import en from "./translations/en.json";
import { Translation } from "./translations/translations";

class Storage {
  get inviteKey(): string | undefined {
    return localStorage.getItem("inviteKey") ?? undefined;
  }
  set inviteKey(value: string | undefined) {
    localStorage.setItem("inviteKey", value ?? "");
  }

  get language(): string | undefined {
    return localStorage.getItem("language") ?? undefined;
  }

  set language(value: string | undefined) {
    localStorage.setItem("language", value ?? "");
  }

  activeTranslation: Translation = en as unknown as Translation;
}

var storage = new Storage();
export default storage;
