import config from "scripts/config";
import { MeetingInfo, PineAttendance } from "../types/meetingInfo";
import CustomError from "scripts/types/CustomError";

export async function getMeetingInfo(
  inviteKey: string
): Promise<MeetingInfo | CustomError> {
  const response = await fetch(config.backendURL + "/pine/getMeetingInfo", {
    headers: {
      invitekey: inviteKey,
    },
  });
  if (response.ok) return (await response.json()) as MeetingInfo;

  const rawError: CustomError = await response.json();
  return new CustomError(rawError.name, response.status, rawError.message);
}
export async function declineInvitation(inviteKey: string) {
  const response = await fetch(
    config.backendURL + "/pine/updateMeetingAttendance",
    {
      headers: {
        invitekey: inviteKey,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ attendance: PineAttendance.DECLINED }),
    }
  );
  window.location.reload();
}

export async function acceptInvitation(inviteKey: string) {
  const response = await fetch(
    config.backendURL + "/pine/updateMeetingAttendance",
    {
      headers: {
        invitekey: inviteKey,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ attendance: PineAttendance.ACCEPTED }),
    }
  );
  window.location.reload();
}
