import { Translation } from "./translations/translations";

export function millisToTimeString(
  millis: number,
  translation: Translation
): string {
  const seconds = Math.floor(millis / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const years = Math.floor(days / 365);

  // display largest unit of time
  if (years > 0) return `${years} ${translation.time.years}`;
  else if (days > 0) return `${days} ${translation.time.days}`;
  else return `${hours} ${translation.time.hours}`;
}

export function stringTemplateReplacer(
  templateText: string,
  variables: any
): string {
  let filled = templateText;
  for (const key in variables) {
    filled = filled.replace(`$\{${key}}`, variables[key]);
  }
  return filled;
}
