import React from "react";
import storage from "scripts/storage";
import "styles/LanguageSelector.css";
import * as translations from "scripts/translations/translations";

export default function LanguageSelector(props: {
  currentTranslation: translations.Translation;
  onLanguageChange: (lang: string) => void;
}): React.ReactElement {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const currentLang = props.currentTranslation.language;
  const currentTranslation = props.currentTranslation;
  const availableTranslations = translations.translations;

  const setLang = (lang: string) => {
    setMenuOpen(false);

    if (lang === currentLang) return;

    props.onLanguageChange(lang);
  };

  const langOption = (translation: translations.Translation) => {
    return (
      <div
        className="lang-option"
        onClick={() => setLang(translation.language)}
        key={translation.language}
      >
        <img
          src={`/lang/${translation.language}.svg`}
          alt={translation.name}
          className="country-flag"
        />
        {translation.name}
      </div>
    );
  };

  const menu = () => {
    return (
      <div className="dropdown-menu">
        {Object.values(availableTranslations).map((translation) =>
          langOption(translation)
        )}
      </div>
    );
  };
  return (
    <div
      className="language-selector"
      onClick={() => {
        setMenuOpen(!menuOpen);
      }}
    >
      <div className="dropdown-btn">
        <img src="/assets/globe.svg" className="globe" alt="globe symbol" />
        <p>{currentTranslation.name}</p>
        <img
          src="/assets/caret-down.svg"
          className="caret"
          alt="dropdown symbol"
        />
      </div>
      {menuOpen ? menu() : null}
    </div>
  );
}
