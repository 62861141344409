import config from "scripts/config";
import CustomError from "scripts/types/CustomError";
import MetaData from "scripts/types/metadata";

export async function getMetaData(
  inviteKey: string
): Promise<MetaData | CustomError> {
  const request = await fetch(config.backendURL + "/pine/getOfficeMetaInfo", {
    headers: {
      invitekey: inviteKey,
    },
  });

  if (request.ok) return (await request.json()) as MetaData;

  const rawError = await request.json();
  return new CustomError(rawError.name, request.status, rawError.message);
}
