import storage from "scripts/storage";
import { MeetingInfo, PineAttendance } from "scripts/types/meetingInfo";
import MetaData from "scripts/types/metadata";
import "../styles/MeetingInfoDisplay.css";
import {
  millisToTimeString,
  stringTemplateReplacer,
} from "scripts/stringHelper";
import { acceptInvitation, declineInvitation } from "scripts/requests/meeting";
import { ReactComponent as Check } from "../assets/check.svg";
import { ReactComponent as XMark } from "../assets/xmark.svg";
import { useState } from "react";

export default function MeetingInfoDisplay(props: {
  meetingInfo: MeetingInfo;
  metaData?: MetaData;
  reload: () => void;
}) {
  const meetingInfo = props.meetingInfo;
  const translation = storage.activeTranslation;

  const subTitle =
    meetingInfo.attendance === PineAttendance.UNKNOWN
      ? undefined
      : meetingInfo.attendance === PineAttendance.ACCEPTED
      ? translation.meeting.acceptInvitation.titleAlreadyAccepted
      : translation.meeting.declineInvitation.titleAlreadyDeclined;

  const [buttonsDisabled, disableButtons] = useState(false);

  const denyButton = () => {
    return (
      <button
        disabled={
          meetingInfo.attendance === PineAttendance.DECLINED || buttonsDisabled
        }
        onClick={() => {
          disableButtons(true);
          declineInvitation(storage.inviteKey!).then(() => props.reload());
        }}
        className={
          meetingInfo.attendance == PineAttendance.DECLINED || buttonsDisabled
            ? "disabled"
            : "destructive"
        }
        type="reset"
      >
        <span
          title={
            meetingInfo.attendance === PineAttendance.DECLINED
              ? translation.meeting.declineInvitation.titleAlreadyDeclined
              : translation.meeting.declineInvitation.title
          }
        >
          <XMark fill="white" height="2rem" />
          {translation.meeting.declineInvitation.button}
        </span>
      </button>
    );
  };

  const acceptButton = () => {
    return (
      <button
        disabled={
          meetingInfo.attendance === PineAttendance.ACCEPTED || buttonsDisabled
        }
        onClick={() => {
          disableButtons(true);
          acceptInvitation(storage.inviteKey!);
        }}
        className={
          meetingInfo.attendance == PineAttendance.ACCEPTED || buttonsDisabled
            ? "disabled"
            : "accept"
        }
        type="submit"
      >
        <span title={translation.meeting.acceptInvitation.title}>
          <Check fill="white" height="2rem" />
          {translation.meeting.acceptInvitation.button}
        </span>
      </button>
    );
  };

  return (
    <div className="meeting-container">
      <p>
        {stringTemplateReplacer(translation.meeting.welcome, {
          givenName: meetingInfo.attendee.firstName,
          surname: meetingInfo.attendee.lastName,
          officeName: meetingInfo.meeting.company,
        })}
      </p>
      <p>
        <b>{translation.meeting.host}</b>: {meetingInfo.host.firstName}{" "}
        {meetingInfo.host.lastName}
      </p>
      <p>
        <b>{translation.meeting.meetingName}</b>: {meetingInfo.meeting.title}
      </p>
      <p>
        <b>{translation.meeting.startMeeting}</b>:{" "}
        {meetingInfo.meeting.start.toLocaleString(undefined, {
          timeZone: props.metaData?.timezone,
        })}{" "}
        {props.metaData?.timezone}
      </p>
      <p>
        <b>{translation.meeting.endMeeting}</b>:{" "}
        {meetingInfo.meeting.end.toLocaleString(undefined, {
          timeZone: props.metaData?.timezone,
        })}{" "}
        {props.metaData?.timezone}
      </p>
      <p>
        <b>{translation.meeting.location}</b>: {meetingInfo.meeting.location}
      </p>
      {meetingInfo.meeting.directions !== undefined ? (
        <div>
          <b>{translation.meeting.directions}</b>:{" "}
          <div
            dangerouslySetInnerHTML={{ __html: meetingInfo.meeting.directions }}
            className="descriptionContainer"
          />
        </div>
      ) : null}

      <p>
        <b>{subTitle}</b>
      </p>
      <div className="acceptanceButtons">
        {denyButton()}
        {acceptButton()}
      </div>
    </div>
  );
}
