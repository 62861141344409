export enum PineAttendance {
  UNKNOWN = 0,
  ACCEPTED = 1,
  DECLINED = 2,
}

export interface MeetingInfo {
  host: {
    firstName: string;
    lastName: string;
  };
  attendee: {
    email: string;
    firstName: string;
    lastName: string;
  };
  meeting: {
    start: Date;
    end: Date;
    title: string;
    company: string;
    location: string;
    directions: string;
  };
  attendance: PineAttendance;
}

export const EmptyMeetingInfo: MeetingInfo = {
  host: {
    firstName: "",
    lastName: "",
  },
  attendee: {
    email: "",
    firstName: "",
    lastName: "",
  },
  meeting: {
    start: new Date(),
    end: new Date(),
    title: "",
    company: "",
    location: "",
    directions: "",
  },
  attendance: PineAttendance.UNKNOWN,
};
