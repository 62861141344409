import de from "./de.json";
import en from "./en.json";
import fr from "./fr.json";

export const translations: Translation[] = [
  en as unknown as Translation,
  de as unknown as Translation,
  fr as unknown as Translation,
];

export function getTranslation(language: string | undefined): Translation {
  if (language === undefined) return en as unknown as Translation;
  const translation = translations.find((t) => t.language === language);
  if (translation === undefined) return en as unknown as Translation;
  return translation;
}

export function getBrowserLanguageKey(): string | undefined {
  const languages = navigator.languages;
  const availableLanguages = translations.map((t) => t.language);
  for (const language of languages) {
    for (const availableLanguage of availableLanguages) {
      if (language.includes(availableLanguage)) {
        return availableLanguage;
      }
    }
  }
  return undefined;
}

export interface Translation {
  language: string;
  name: string;
  imagePath: string;
  meeting: {
    host: string;
    title: string;
    welcome: string;
    meetingName: string;
    startMeeting: string;
    endMeeting: string;
    location: string;
    directions: string;
    declineInvitation: {
      button: string;
      title: string;
      titleAlreadyDeclined: string;
    };
    acceptInvitation: {
      button: string;
      title: string;
      titleAlreadyAccepted: string;
    };
    dataDisclaimer: {
      normal: string;
    };
  };
  invalidURL: string;
  time: {
    years: string;
    days: string;
    hours: string;
  };
}
