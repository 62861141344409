import MeetingInfoDisplay from "components/MeetingInfoDisplay";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  acceptInvitation,
  declineInvitation,
  getMeetingInfo,
} from "scripts/requests/meeting";
import { getMetaData } from "scripts/requests/meta";
import storage from "scripts/storage";
import CustomError from "scripts/types/CustomError";
import { MeetingInfo } from "scripts/types/meetingInfo";
import MetaData from "scripts/types/metadata";

export default function Welcome() {
  const [meetingInfo, setMeetingInfo] = useState(
    undefined as MeetingInfo | undefined
  );
  const [metadata, setMetadata] = useState(undefined as MetaData | undefined);
  const [error, setError] = useState(undefined as CustomError | undefined);

  var { key } = useParams();
  storage.inviteKey = key ?? storage.inviteKey;

  const loadMetadata = () => {
    getMetaData(storage.inviteKey!!).then((meta) => {
      if (meta instanceof CustomError) {
      } else {
        setMetadata(meta);
      }
    });
  };

  const loadMeetingInfo = () => {
    getMeetingInfo(storage.inviteKey!!).then((info) => {
      if (info instanceof CustomError) {
        setMeetingInfo(undefined);
        setError(info);
      } else {
        info.meeting.start = new Date(info.meeting.start);
        info.meeting.end = new Date(info.meeting.end);
        setMeetingInfo(info);
        setError(undefined);
      }
    });
  };

  useEffect(() => {
    loadMetadata();
    loadMeetingInfo();
  }, []);

  if (storage.inviteKey === undefined) {
    window.location.href = "/";
    return null;
  }

  return (
    <div>
      {meetingInfo === undefined && error === undefined ? <Loading /> : null}
      {meetingInfo !== undefined ? (
        <MeetingInfoDisplay
          meetingInfo={meetingInfo}
          reload={loadMeetingInfo}
          metaData={metadata}
        />
      ) : null}
      {error !== undefined ? <MeetingLoadingError error={error} /> : null}
    </div>
  );
}

function Loading() {
  return <div>Loading</div>;
}

function MeetingLoadingError(props: { error: CustomError }) {
  return (
    <div>
      <h2>An error occurred while loading the meeting info</h2>
      <small>Error code: {props.error.statusCode}</small>
      <br />
      <small>Error message: {props.error.name}</small>
    </div>
  );
}
