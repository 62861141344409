import storage from "scripts/storage";

export default function EmptyPine() {
  const translation = storage.activeTranslation;

  return (
    <div>
      <h1>{translation.invalidURL}</h1>
    </div>
  );
}
