import React, { useEffect } from "react";
import "styles/App.css";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import Welcome from "./Welcome";
import EmptyPine from "./Empty";
import storage from "scripts/storage";
import LanguageSelector from "components/LanguageSelector";
import {
  getBrowserLanguageKey,
  getTranslation,
} from "scripts/translations/translations";

function App() {
  const storedLang = storage.language;
  if (storedLang !== undefined)
    storage.activeTranslation = getTranslation(storedLang);
  else if (getBrowserLanguageKey() !== undefined)
    storage.activeTranslation = getTranslation(getBrowserLanguageKey());
  else storage.activeTranslation = getTranslation("en");

  const routes = [
    {
      path: "/invite/:key?",
      element: <Welcome />,
    },
    {
      path: "/",
      element: <EmptyPine />,
    },
  ];

  const [f, forceRender] = React.useState(false);

  const onLanguageChange = (lang: string) => {
    storage.language = lang;
    storage.activeTranslation = getTranslation(lang);
    forceRender(!f);
  };

  return (
    <BrowserRouter>
      <header>
        <div id="dj_header">
          <img
            src="/assets/doorjames-logo-white.svg"
            alt="Door James logo"
            className="djLogo"
          />
          <h1>{storage.activeTranslation.meeting.title}</h1>
        </div>
        <LanguageSelector
          onLanguageChange={onLanguageChange}
          currentTranslation={storage.activeTranslation}
        />
      </header>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
